.google-signin-btn {
  background-color: #4285F4; /* Google's brand blue */
  color: white;
  font-size: 16px;
  font-family: Arial, sans-serif;
  padding: 10px 24px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
  transition: background-color 0.3s, box-shadow 0.2s;
}

.google-signin-btn:hover {
  background-color: #357ae8; /* Slightly darker blue on hover */
  box-shadow: 0 0 6px #4285F4; /* Lighter shadow for a glowing effect */
}

.google-signin-btn:active {
  background-color: #3367D6; /* Even darker blue on click */
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.3); /* Deeper shadow on click */
}