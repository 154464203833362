/* searchbar.css */
.search-form {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;

  }
  
  .search-input {
    padding: 10px 15px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 300px; /* Adjust width as needed */
    margin-right: 10px;
    outline: none; /* Remove the default focus outline */
    font-family: "Jost";
  }
  
  .search-input:focus {
    border-color: purple; /* Highlight input when focused */
  }

  @media (max-width: 600px) {
    .search-input {
      max-width: 90%; /* Full width on small screens */
      margin-right: 5px;
      margin-left: 5px;
    }
  
    .button {
      max-width: 90%; /* Full width button for easier click */
    }

  }