.home-title {
  font-size: 60px;
  font-family: "Madimi One";
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.homepage {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: #ffffff;
}

.home-header {
  background-image: linear-gradient(purple, orange, #ffffff);
  width: 100%;
}

.trending-posts {
  display: flex;
  flex-wrap: wrap;
  max-width: 80%;
  align-items: center;
  justify-content: center;
}

.iframe-container-hp {
  position: relative;
  width: 250px;  /* Set the desired width */
  height: 250px; /* Set the desired height */
  overflow: hidden;
  margin: 20px;
  box-shadow: 2px 2px 10px gray;
}

.click-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0); /* transparent but clickable */
  cursor: pointer; /* Indicates clickable area */
}

.click-overlay:hover {
  background-color: lightgray;
  opacity: 30%;
}

.trend-header {
  font-size: 30px;
  margin-top: 20px;
}

.sign-in {
  margin-bottom: 40px;
}

.search-bar {
  margin-bottom: 60px;
}

/* General styling for mobile */
@media (max-width: 768px) {

}

/* Additional styling to ensure usability on even smaller devices */
@media (max-width: 350px) {
  .home-title {
    font-size: 32px;
  }

  .trending-posts {
    padding: 5px; /* Less padding for more space efficiency */
  }
}
