/* Footer.css */
.footer {
    background-color: #f8f8f8; /* Light gray background */
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: center; /* Center-align text */
    border-top: 1px solid #e7e7e7; /* Subtle border at the top */
    width: 100%;
  }
  
  .footer-links {
    margin-bottom: 10px;
  }
  
  .footer-link {
    margin: 0 15px; /* Spacing between links */
    text-decoration: none; /* Remove underline */
    color: #000; /* Link color */
  }
  
  .footer-link:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  .footer-text {
    color: #888; /* Lighter text color */
    font-size: 14px; /* Adjust font size */
  }
  