.edit-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  min-width: 600px;
  min-height: 600px;
}

.tag-description textarea {
  width: 90%;  /* Makes the textarea take up the full width of its container */
  height: 500px; /* Larger height for more text visibility */
  padding: 10px; /* Comfortable padding around the text */
  border: 1px solid #ccc; /* Subtle, soft border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  resize: none; /* Disables resizing, remove if you want users to resize */
  font-size: 16px; /* Sufficiently large font size */
  font-family: Arial, sans-serif; /* Sets a pleasant and readable font */
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus */
  margin-bottom: 30px;
}

.edit-popup textarea {
  width: 90%;  /* Makes the textarea take up the full width of its container */
  height: 500px; /* Larger height for more text visibility */
  padding: 10px; /* Comfortable padding around the text */
  border: 1px solid #ccc; /* Subtle, soft border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  resize: none; /* Disables resizing, remove if you want users to resize */
  font-size: 16px; /* Sufficiently large font size */
  font-family: Arial, sans-serif; /* Sets a pleasant and readable font */
  transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus */
  margin-bottom: 30px;
}

.edit-popup textarea:focus {
  border-color: purple; /* Focus color that stands out */
  box-shadow: 0 0 8px rgba(66, 133, 244, 0.5); /* Glowing effect on focus */
  outline: none; /* Removes default outline to use custom styles */
}

.edit-popup button {
  margin-right: 10px;
}

.post-page {
  display: flex;
  flex-direction: column;
}

.wiki {
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 50px;
  background-color: #eeeeee;
  max-width: 600px;
  min-width: 300px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-image: linear-gradient(purple, orange, #ffffff);
  width: 100%;
}

.title {
  margin: 20px;
  font-family: "Madimi One";
  font-size: 48px;
  color: black; /* Set your preferred color */
  text-decoration: none; /* Removes underline */
  cursor: pointer; /* Changes cursor to indicate clickability */
}

.title:hover {
  color: transparent;
  text-shadow: 0 0 2px rgba(0,0,0,0.8);
}

.login-button {
  height: 30px;
  align-self: center;
}

.body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.circle-overlay {
  position: absolute;
  top: 40%; /* Position the circle 40% from the top of the iframe */
  left: 40%; /* Position the circle 40% from the left of the iframe */
  width: 40px; /* Circle size */
  height: 40px;
  background-color: rgba(100, 100, 100, 0.9); /* Red circle with 50% opacity */
  border-radius: 50%; /* Makes the div a circle */
  pointer-events: none; /* Allows click events to pass through to the iframe */
}

.wiki-content {
  font-size: 24px;
  margin: 30px;
  text-align: left;
}

button {
  padding: 10px 15px;
  margin-right: 10px;
  background-color: gray; /* Google's brand blue */
  font-family: "Madimi One";
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: purple; /* Slightly darker blue on hover */
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(66,133,244,0.5); /* Adding focus outline for accessibility */
}

.iframe-container {
  position: relative;
  box-shadow: 5px 5px 10px gray;
}

.iframe-container:hover {
  position: relative;
  box-shadow: 15px 15px 20px gray;
}

.iframe-container.add-tag-mode {
  position: relative;
}

.iframe-container.add-tag-mode iframe {
  opacity: 0.5; /* Gray out the iframe */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlay p {
  font-size: 20px;
}

.overlay button {
  padding: 10px;
  margin-top: 20px;
}

.tags-list {
  margin-top: 20px;
}

.tag-item {
  display: flex;
  align-items: center; /* Ensure vertical alignment at the center */
  justify-content: flex-start; /* Align items to the start of the container */
  margin-bottom: 20px;
  margin-left: 20px;
}

.tag-item input, .tag-item button {
  margin-right: 5px;
  margin-left: 10px;
}

.tag-circle {
  position: absolute;
  background-color: rgba(128, 128, 128, 0.5); /* Gray with opacity */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%; /* Makes it circular */
  pointer-events: none; /* Allows clicks to pass through to the iframe */
  transform: translate(-50%, -50%); /* Center the text inside the circle */
  font-size: 12px; /* Adjust font size as needed */
  transition: all 0.3s ease; /* Smooth transition for size, color, and font changes */
}

.tag-circle-right {
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Circle shape */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.5);
  font-size: 16px; /* Adjust as needed */
  color: white;
  margin-right: 20px; /* Space between the circle and description */
  margin-left: 10px;
}

.tag-circle-and-description {
  display: flex;
  align-items: center; /* This will vertically align the circle and description */
  margin-right: 15px; /* Optional: Adds space between tag items */
}

.tag-description {
  display: flex;
  align-items: center; /* Center the description text vertically */
  text-align: left;  
  margin-left: 5px;
  font-size: 16px;
  max-width: 80%;
}

/* Basic adjustments for mobile screens */
@media (max-width: 768px) {
  .post-page, .header, .wiki, .body {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align the content for better focus */
    /* padding: 10px; Reduce padding to increase space */
  }

  .wiki {
    max-width: 100%; /* Full width to use the available space */
    padding: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .iframe-container {
    width: 90%; /* Reduce width to fit the screen */
    height: auto; /* Adjust height proportionally */
    margin-top: 20px; /* More space above the embed */
    margin: 20px auto; /* Center it horizontally and add vertical spacing */
    box-shadow: 3px 3px 6px rgba(0,0,0,0.1); /* Optional: soften the shadow on smaller screens */
    max-width: 400px;
  }

  .iframe-container iframe {
    width: 100%; /* Full width of the container to maximize space */
    height: auto; /* Maintain aspect ratio based on the width */
    min-height: 300px; /* Minimum height to ensure content visibility */
    height: 600px;
  }

  /* If you're using a cover or overlay on the iframe that requires positioning */
  .overlay {
    width: 100%; /* Full width to cover the iframe entirely */
    height: 100%; /* Full height to cover the iframe entirely */
  }

  .tag-circle-right, .tag-circle {
    width: 40px; /* Slightly larger for easier interaction */
    height: 40px;
    font-size: 18px; /* Larger font size for readability */
  }

  button {
    padding: 12px 18px; /* Larger padding for easier tapping */
    font-size: 18px; /* Larger font for readability */
  }

  .edit-popup textarea, .edit-popup {
    min-width: 300px; /* Make the popup and textarea take up most of the screen */
    min-height: 300px; /* Less height to fit the screen better */
    font-size: 18px; /* Larger font size for easier editing */
  }
}
